module.exports = {
  home: [
    {
      img: 'logo_cove_4x.png',
      alt: 'logo cove',
      width: '89px',
    },
    {
      img: 'logo_udo_walz_4x.png',
      alt: 'Udo Walz',
      width: '140px',
    },
    {
      img: 'logo_klier_hair_group_4x.png',
      alt: 'Klier hair group',
      width: '140px',
    },
    {
      img: 'logo_schuster_4x.png',
      alt: 'Schuster',
      width: '140px',
    },
    {
      img: 'logo_novitas_4x.png',
      alt: 'Novitas',
      width: '133px',
    },
  ],
  shoreSalesPeople: ['sales_oezguercan.png', '_roland_02', '_vladi'],
  shorePayPeople: ['sales_cem.png', 'sales_oezguercan.png'],
  shoreSupportPeople: ['_roland_02.png', '_vladi.png', 'sales_oezguercan.png'],
  emailProvider: [
    {
      icon: 'logo_gmail_4x.png',
      url: 'https://mail.google.com/mail/',
    },
    {
      icon: 'logo_gmx_4x.png',
      url: 'https://www.gmx.net',
    },
    {
      icon: 'logo_web_de_4x.png',
      url: 'https://www.web.de',
    },
    {
      icon: 'logo_telekom_4x.png',
      url: 'https://www.telekom.de/e-mail',
    },
  ],
  shoreSuccessPeople: ['shore_profile_elena.png', 'sales_cem.png'],
  shoreKeyAccountPeople: ['sales_kerim.png', 'sales_cem.png'],
  community: [
    {
      icon: 'logo_apple_2x.png',
      classN: 'is-custom',
    },
    {
      icon: 'logo_loreal_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_mediamarkt_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_instagram_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_yext_2x.png',
      classN: 'is-custom',
    },
    {
      icon: 'logo_epages_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_sumup_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_google_2x.png',
      classN: 'is-3',
    },
    {
      icon: 'logo_jimdo_2x.png',
      classN: 'is-3',
    },
  ],
  partnersImg: [
    {
      img: 'logo_cove_white.png',
      className: '',
      alt: 'logo cove',
      width: '64',
      height: '64',
      maxWidth: '200',
    },
    {
      img: 'logo_kuhn_white.png',
      className: '',
      alt: 'logo kuhn',
      width: '40',
      height: '64',
      maxWidth: '200',
    },
    {
      img: 'logo_novitas.png',
      className: '',
      alt: 'logo novitas',
      width: '100',
      height: '64',
      maxWidth: '200',
    },
  ],
  health: [
    {
      img: 'logo_rpa_4x.png',
      alt: 'logo myssage',
      width: '134px ',
    },
    {
      img: 'logo_adam_eve_4x.png',
      alt: 'logo adam eve',
      width: '107px',
    },
    {
      img: 'logo_novitas_4x.png',
      alt: 'logo novitas',
      width: '100',
    },
    {
      img: 'logo_kkh_4x.png',
      alt: 'kkh',
      width: '148px',
    },
  ],
  beauty: [
    {
      img: 'logo_myssage_4x.png',
      alt: 'logo myssage',
      width: '134px ',
    },
    {
      img: 'logo_adam_eve_4x.png',
      alt: 'logo adam eve',
      width: '107px',
    },
  ],
  spa: [
    {
      img: 'logo_myssage_4x.png',
      alt: 'logo myssage',
      width: '134px ',
    },
    {
      img: 'logo_adam_eve_4x.png',
      alt: 'logo adam eve',
      width: '107px',
    },
  ],
  hair: [
    {
      img: 'logo_room405_4x.png',
      alt: 'Room 405',
      width: '140px',
    },
    {
      img: 'logo_klier_hair_group_4x.png',
      alt: 'Klier hair group',
      width: '140px',
    },
    {
      img: 'logo_udo_walz_4x.png',
      alt: 'Udo Walz',
      width: '104px',
    },
    {
      img: 'logo_trend_hair_4x.png',
      alt: 'Trend Hair',
      width: '97px',
    },
    {
      img: 'logo_vandell_4x.png',
      alt: 'Vandell',
      width: '110px',
    },
  ],
  coaching: [
    {
      img: 'logo_novitas_4x.png',
      alt: 'Novitas BKK',
      width: '127px',
    },
    {
      img: 'logo_localsearch_4x.png',
      alt: 'Localsearch',
      width: '145px',
    },
    {
      img: 'logo_bettenrid_4x.png',
      alt: 'Bettenrid',
      width: '123px',
    },
    {
      img: 'logo_kkh_4x.png',
      alt: 'kkh',
      width: '148px',
    },
  ],
  sport: [
    {
      img: 'logo_schuster_4x.png',
      alt: 'logo schuster',
      width: '139px',
    },
    {
      img: 'logo_novitas_4x.png',
      alt: 'Novitas BKK',
      width: '127px',
    },
    {
      img: 'logo_localsearch_4x.png',
      alt: 'Localsearch',
      width: '145px',
    },
    {
      img: 'logo_cove_4x.png',
      alt: 'cove',
      width: '89px',
    },
    {
      img: 'logo_kkh_4x.png',
      alt: 'kkh',
      width: '148px',
    },
  ],
  fashion: [
    {
      img: 'logo_brautundliebe_4x.png',
      alt: 'logo Braut und Liebe',
      width: '89px',
    },
    {
      img: 'logo_cove_4x.png',
      alt: 'logo cove',
      width: '89px',
    },
    {
      img: 'logo_kuhn_4x.png',
      alt: 'Novitas kuhn',
      width: '54px',
    },
    {
      img: 'logo_schuster_4x.png',
      alt: 'schuster',
      width: '139px',
    },
    {
      img: 'logo_localsearch_4x.png',
      alt: 'localsearch',
      width: '145px',
    },
  ],
  gem: [
    {
      img: 'logo_cove_4x.png',
      alt: 'cove',
      width: '89px',
    },
    {
      img: 'logo_kuhn_4x.png',
      alt: 'Novitas kuhn',
      width: '54px',
    },
    {
      img: 'logo_schuster_4x.png',
      alt: 'logo schuster',
      width: '139px',
    },
    {
      img: 'logo_localsearch_4x.png',
      alt: 'Localsearch',
      width: '145px',
    },
  ],
  partners: [
    {
      img: 'logo_cove.png',
      alt: 'Cove',
    },
    {
      img: 'logo_oliver_schmidt.png',
      alt: 'Oliver Schmidt',
    },
    {
      img: 'logo_schuster.png',
      alt: 'Schuster',
    },
    {
      img: 'logo_trend_hair.png',
      alt: 'Trend hair',
    },
    {
      img: 'logo_kkh.png',
      alt: 'kkh',
    },
    {
      img: 'logo_kuhn.png',
      alt: 'Kuhn',
    },
    {
      img: 'logo_klier_hair_group_1.png',
      alt: 'Klier hair group',
    },
    {
      img: 'logo_ece.png',
      alt: 'ece',
    },
    {
      img: 'logo_vandell.png',
      alt: 'Vandell',
    },
    {
      img: 'logo_bettenrid.png',
      alt: 'Bettenrid',
    },
    {
      img: 'logo_localsearch.png',
      alt: 'Localsearch',
    },
    {
      img: 'logo_rpa.png',
      alt: 'Rotpunkt Apotheken',
    },
    {
      img: 'logo_novitas.png',
      alt: 'Novitas BKK',
    },
  ],
  socialNetworkIcons: [
    {
      img: 'network_logo_jimdo.svg',
      alt: 'jimdo',
      classes: 'small-icon',
    },
    {
      img: 'network_logo_facebook.svg',
      alt: 'facebook',
    },
    {
      img: 'network_logo_google.svg',
      alt: 'google',
    },
    {
      img: 'network_logo_instagram.png',
      alt: 'instagram',
    },
    {
      img: 'network_logo_yext.svg',
      alt: 'yext',
    },
    {
      img: 'network_logo_apple.svg',
      alt: 'apple',
      classes: 'small-icon',
    },
  ],
  trust: [
    {
      img: 'shore_trust_seals_01_kassensichv_new.png',
      className: 'trust-img',
      alt: 'trust image kassensichv',
      width: '90',
      height: '90',
      maxWidth: '140',
    },
    {
      img: 'shore_trust_seals_01_gobd_new.png',
      className: 'trust-img',
      alt: 'trust image trust seals',
      width: '90',
      height: '90',
      maxWidth: '140',
    },
    {
      img: 'shore_trust_seals_01_made_in_germany.png',
      className: 'trust-img',
      alt: 'trust image made in germany',
      width: '122',
      height: '90',
      maxWidth: '140',
    },
  ],
  company: [
    {
      img: 'slider_company_01.jpg',
      alt: 'shore people laughing',
    },
    {
      img: 'slider_company_02.jpg',
      alt: 'shore people laughing again',
    },
    {
      img: 'slider_company_03.jpg',
      alt: 'be pioneering',
    },
    {
      img: 'slider_company_04.jpg',
      alt: 'just chilling',
    },
    {
      img: 'slider_company_05.jpg',
      alt: 'shore people laughing once more',
    },
    {
      img: 'slider_company_06.jpg',
      alt: 'shore design team',
    },
  ],
  events: [
    {
      img: 'slider_events_01.jpg',
      alt: 'shore people eating',
    },
    {
      img: 'slider_events_02.jpg',
      alt: 'shore people eating cupcakes',
    },
    {
      img: 'slider_events_03.jpg',
      alt: 'shore people talking',
    },
    {
      img: 'slider_events_04.jpg',
      alt: 'shore people smiling again',
    },
    {
      img: 'slider_events_05.jpg',
      alt: 'shore man drinking',
    },
    {
      img: 'slider_events_06.jpg',
      alt: 'shore people smiling',
    },
    {
      img: 'slider_events_07.jpg',
      alt: 'shore people eating cake',
    },
    {
      img: 'slider_events_08.jpg',
      alt: 'shore people smiling once more',
    },
    {
      img: 'slider_events_09.jpg',
      alt: 'shore people partying',
    },
  ],
  pay: [
    {
      img: 'logo_electronic_cash_4x.png',
      alt: 'EC card',
      width: '29',
      height: '35',
      maxWidth: '100',
    },
    {
      img: 'logo_mastercard_4x.png',
      alt: 'Mastercard',
      width: '37',
      height: '32',
      maxWidth: '100',
    },
    {
      img: 'logo_visa_4x.png',
      alt: 'Visa',
      width: '49',
      height: '20',
      maxWidth: '100',
    },
    {
      img: 'logo_american_express_4x.png',
      alt: 'American express',
      width: '29',
      height: '35',
      maxWidth: '100',
    },
    {
      img: 'logo_vpay_4x.png',
      alt: 'vpay',
      width: '29',
      height: '35',
      maxWidth: '100',
    },
    {
      img: 'logo_apple_pay_4x.png',
      alt: 'apple pay',
      width: '49',
      height: '20',
      maxWidth: '100',
    },
    {
      img: 'logo_google_pay_4x.png',
      alt: 'google pay',
      width: '49',
      height: '20',
      maxWidth: '100',
    },
    {
      img: 'logo_wireless_4x.png',
      alt: 'wireless pay',
      width: '27',
      height: '34',
      maxWidth: '100',
    },
  ],
};
