import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import Button from '../components/ui/button';

import { LocalizedPageContext } from '../utils/types';
import images from '../utils/store/images';
import { ImageGallery } from '../components/ui/imageGallery';

const ThankYouPage: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { lang, alternateUrls, key } = pageContext;

  const { t } = useTranslation([key, 'general']);

  const { shoreSupportPeople } = images;

  const data = pageContext.i18nResources[lang][key];
  return (
    <Layout
      pageContext={pageContext}
      secondNav
      wrapperClasses="secondary_page"
      mainClasses="text-centered is-centered is-vcentered"
      navClasses="transparent"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <section className="is-centered text-centered">
        <h1 className="title-large title text-black">
          {t('hero.title')}
          <br />
          {data?.hero?.small && t('hero.small')}
        </h1>
        {data?.hero?.text && <p> {t('hero.text')}</p>}
        {data?.hero?.heroImage && (
          <ImageGallery
            name={t('hero.heroImage')}
            maxWidth="500"
            alt="thanks for coming around"
          />
        )}

        {key === 'confirm' && (
          <div className="image-container image-list">
            {Object.keys(shoreSupportPeople).map((k: any) => {
              return (
                <ImageGallery
                  name={shoreSupportPeople[k]}
                  className="sales_person"
                  key={k}
                  alt="our support for you"
                />
              );
            })}
          </div>
        )}

        <Button primary to="/" size="large">
          {t('back_home', { ns: 'general' })}
        </Button>
        <br />
        {data?.hero?.sub && <p className="small"> {t('hero.sub')}</p>}
      </section>
    </Layout>
  );
};

export default ThankYouPage;
